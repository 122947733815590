import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/226925586-19a376a1-7430-410f-b060-5e77fae41933.png" />
    <h2>{`Usage`}</h2>
    <p>{`A nav list organizes navigation links for the user's current context and indicates which view they're currently on. It is typically used as a sidebar that changes what is rendered in the main content area.`}</p>
    <h3>{`States`}</h3>
    <img width="960" role="presentation" src="https://github.com/primer/design/assets/2313998/a3d4180b-239e-40a8-8a11-d7cf40405159" />
    <p><strong parentName="p">{`Resting:`}</strong>{` The default state. The link is not the one currently being shown.`}</p>
    <p><strong parentName="p">{`Current:`}</strong>{` Indicates that this link is the current view being shown.`}</p>
    <p><strong parentName="p">{`Inactive:`}</strong>{` Indicates that the link href is unavailable due to a system error. See `}<a parentName="p" {...{
        "href": "/ui-patterns/degraded-experiences#degraded-navigation"
      }}>{`degraded experience guidelines`}</a>{` for more information.`}</p>
    <p><strong parentName="p">{`Loading:`}</strong>{` Indicates that the link href is still being calculated. See `}<a parentName="p" {...{
        "href": "/ui-patterns/loading#navigation"
      }}>{`loading guidelines`}</a>{` for more information.`}</p>
    <p><strong parentName="p">{`Expanded sub-items:`}</strong>{` The item is expanded to show its sub-items.`}</p>
    <p><strong parentName="p">{`Collapsed sub-items:`}</strong>{` The item has sub-items, but they are hidden.`}</p>
    <p><strong parentName="p">{`Action list item interactive states:`}</strong>{` Hover and focus states match the action list items.`}</p>
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`Nav list items should only ever be links. Do not try and add items to the nav list that are buttons or non-interactive content.`}
        <ul parentName="li">
          <li parentName="ul">{`If you need to render a list of actions or a list of actions mixed with links, use the `}<a parentName="li" {...{
              "href": "/components/action-list"
            }}>{`action list`}</a>{` instead`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Avoid inconsistent use of leading visuals. Ideally all items have a leading visual, or no items have them. This keeps the link text aligned at the start of the item, making the links easier to scan.`}</li>
      <li parentName="ul">{`Associate related items using a `}<a parentName="li" {...{
          "href": "/components/nav-list#group-list-sections"
        }}>{`group`}</a>{` or `}<a parentName="li" {...{
          "href": "/components/nav-list#pseudo-grouping-using-a-divider"
        }}>{`divider`}</a>{` when it makes the content easier to understand.`}</li>
      <li parentName="ul">{`Items should be listed in a logical or intuitive order.`}</li>
    </ul>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img role="presentation" width="456" src="https://user-images.githubusercontent.com/2313998/226925595-ab08263b-600d-43be-b56a-c9b6af8950a1.png" />
    <Caption mdxType="Caption">Use leading visuals consistently.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img role="presentation" width="456" src="https://user-images.githubusercontent.com/2313998/226925597-717c5e21-5792-4d4c-94bc-5a798ca486ce.png" />
    <Caption mdxType="Caption">Avoid mixing items that have leading visuals with items that don't.</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img role="presentation" width="456" src="https://user-images.githubusercontent.com/2313998/226925601-ad39870d-56d4-4696-911b-5f05ccd436d4.png" />
    <Caption mdxType="Caption">Only use dividers to visually group items.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img role="presentation" width="456" src="https://user-images.githubusercontent.com/2313998/226925603-d17f11b7-1c80-42d8-bd0e-4fc329ac6e2b.png" />
    <Caption mdxType="Caption">Don't use dividers to separate individual items.</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Anatomy`}</h2>
    <p>{`Nav list items are rendered using `}<a parentName="p" {...{
        "href": "/components/action-list"
      }}>{`action list`}</a>{` items, so they share anatomy, styles, and some options.`}</p>
    <h2>{`Options`}</h2>
    <h3>{`Leading and trailing visuals`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/226925606-c1d6d354-5692-410e-a27e-4e25f1c264ca.png" />
    <p><strong parentName="p">{`Leading visuals`}</strong>{` are commonly used to show a visual hint for what the item is. For example, an icon or avatar that represents the view being linked to.`}</p>
    <p><strong parentName="p">{`Trailing visuals`}</strong>{` are commonly used to show auxiliary information about the item. For example, a count of unread notifications on the page.`}</p>
    <p>{`Nav list items may have both a leading and a trailing visual.`}</p>
    <h3>{`Grouped list sections`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/226925613-71c86520-394d-46b7-a29d-090268d4e0cb.png" />
    <p>{`You can semantically group your nav list into multiple lists using groups. You can choose whether or not to show a label for each group.`}</p>
    <p>{`If you want to visually group your nav list into sections but don't want the semantics of nested `}<inlineCode parentName="p">{`<ul>`}</inlineCode>{`s, you may use dividers to visually break your list into sections.`}</p>
    <p>{`Grouping should be used to make long or complex nav lists easier to comprehend.`}</p>
    <h3>{`Sub-items`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/226925617-c5a27545-905f-491e-9059-6389264ab77d.png" />
    <p>{`Sub-items may be collapsed or expanded under a top-level nav list item.`}</p>
    <p>{`The top-level nav list item cannot be a link; it only behaves as a toggle that expands and collapses the sub-items.`}</p>
    <p>{`Sub-items may be used to render nested navigation structures. Up to 4 levels of nesting are supported. If you need to handle a more deeply-nested navigation structure, reconsider the design of your navigation or the information hierarchy.`}</p>
    <p>{`Do not replace your NavList with a `}<a parentName="p" {...{
        "href": "/components/tree-view"
      }}>{`tree view`}</a>{` to support a deeply nested navigation structure. A tree view is never an accessible replacement for navigation, as it serves a different purpose and is not recognized as navigation by assistive technologies.`}</p>
    <h2>{`Responsive layout`}</h2>
    <p>{`For information on responsive layout of a nav list that is used in a sidebar, see the `}<a parentName="p" {...{
        "href": "/navigation#responsive-sidebar-navigation-patterns"
      }}>{`responsive sidebar navigation patterns`}</a>{` section of our navigation guidelines for more information.`}</p>
    <h2>{`Accessibility`}</h2>
    <p>{`Nav lists should always be labeled for assistive technologies, even if the label is visually hidden.`}</p>
    <p>{`Nav list items are links, so they should never contain buttons or other clickable elements.`}</p>
    <p>{`Inactive nav list items are not rendered as `}<inlineCode parentName="p">{`<a>`}</inlineCode>{` tags since we don't know the href value. The only focusabe part of the item is the leading visual. See the `}<a parentName="p" {...{
        "href": "/components/action-list#tooltips-and-dialogs-on-inactive-items"
      }}>{`action list accessibility guidelines`}</a>{` for more details.`}</p>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="NavList" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    {/* TODO: add SplitPageLayout when we have docs for it */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/action-list"
        }}>{`Action list`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/tree-view"
        }}>{`Tree view`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      